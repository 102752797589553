//
// Footer Styles
// --------------------------------------------------

#footer {
	background-color: $color-primary;
	color: $color-white;
	font-size: 14px;
	padding: 3.8rem 0 1.25rem 0;

	@media (max-width: 576px) {
		padding: 3.8rem 0;
	}

	#footer-top {
		display: flex;
		justify-content: center;

		#logo {
			img {
				filter: contrast(1) invert(1) brightness(10);
			}
		}
	}

	#footer-bottom {
		margin-top: 3.8rem;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1rem;

		@media (max-width: 676px) {
			flex-direction: column;
		}

		@media (max-width: 576px) {
			gap: 1rem;
			text-align: center;
			margin-top: 2.8rem;
		}

		.footer-bottom-block {
			display: flex;
			gap: .2rem;
			flex-wrap: wrap;
			align-items: center;

			span {
				display: inherit;
				gap: .2rem;
				margin-right: 1rem;
			}

			a {
				color: $color-white;
			}

			@media (max-width: 576px) {
				gap: .3rem;
				text-align: center;
			}
		}
	}

	.container {
		@media (max-width: 576px) {
			margin: 0;
			max-width: 100%;
		}
	}

}
.powered {
	position: relative;
	color: inherit;
	&:hover {
		color: inherit;
		text-decoration: none;
		#logo-alt-footer-trois {
			fill: #FFD94A;
			transform: scale(1.2);
		}
		#logo-alt-footer-deux {
			fill: #FFD94A;
			transform: scale(1.2);
		}
		#logo-alt-footer-un {
			fill: #FFD94A;
			transform: scale(1.2);
		}
		&:after {
			width: 100px;
		}
	}
	svg {
		overflow: visible;
		width: 50px;
		height: 24px;
		margin: 0 0 0 5px;
		position: relative;
		top: -2px;
		vertical-align: middle;
		width: 34px;
		#logo-alt-footer-trois {
			fill: white;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
			transform: scale(1);
			transform-origin: center;
		}
		#logo-alt-footer-deux {
			fill: white;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
			transform: scale(1);
			transform-origin: center;
		}
		#logo-alt-footer-un {
			fill: white;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;
			transform: scale(1);
			transform-origin: center;
		}
	}
	&:after {
		display: block;
		content: '';
		position: absolute;
		bottom: -2px;
		left: 83px;
		width: 0px;
		height: 2px;
		background-color: #FFD94A;
		transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	}
}
