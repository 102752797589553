@import "../../assets/styles/scss/variables";


#logo {
  max-width: 10rem;
  width: 100%;
  transition: all 0.4s linear;

  a {
    display: flex;
  }

  img {
    width: 100%;
  }

  @media (max-width:1440px) {
    width: 10rem;
  }

  @media (max-width:1280px) {
    width: 10rem;
  }

  @media (max-width:767px) {
    width: 8.95rem;
  }
}
