@import "../../assets/styles/scss/variables";

.bloc-cta {
	height: 100svh;
	color: $color-white;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	@media (max-width: 767px) {
		height: 50svh;
	}

	.container {
		height: 100svh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 2.45rem;

		@media (max-width: 767px) {
			height: 50svh;
		}
	}

	.content {
		max-width: 50rem;

		@media (max-width: 576px) {
			max-width: 100%;
		}

		.title {
			margin-bottom: .75rem;
		}

		.sub-title {
			max-width: 36rem;
		}
	}
}