//
// Font Faces Styles
// @help: url('../webfonts/xxx)
// --------------------------------------------------

@font-face {
  font-family: "Lexend";
  src: url("../webfonts/Lexend/Lexend-Light.ttf") format("truetype"),
       url("../webfonts/Lexend/Lexend-Regular.ttf") format("truetype"),
       url("../webfonts/Lexend/Lexend-Medium.ttf") format("truetype"),
       url("../webfonts/Lexend/Lexend-Bold.ttf") format("truetype"),
       url("../webfonts/Lexend/Lexend-ExtraBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "DM Serif Display";
  src: url("../webfonts/DM_Serif_Display/DMSerifDisplay-Regular.ttf") format("truetype"),
       url("../webfonts/DM_Serif_Display/DMSerifDisplay-Italic.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Font Awesome 6 Duotone";
  src: url("../webfonts/fontawesome/fa-duotone-900.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-duotone-900.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Light";
  src: url("../webfonts/fontawesome/fa-light-300.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-light-300.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Regular";
  src: url("../webfonts/fontawesome/fa-regular-400.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-regular-400.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Sharp";
  src: url("../webfonts/fontawesome/fa-sharp-900.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-sharp-900.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Solid";
  src: url("../webfonts/fontawesome/fa-solid-900.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-solid-900.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Thin";
  src: url("../webfonts/fontawesome/fa-thin-100.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-thin-100.ttf") format("truetype");
}
