//
// Header Styles
// --------------------------------------------------
:root {
	--header-height: 5.89rem;
}

$header-height: var(--header-height);

#header-homepage {
	height: 100vh;
	background-color: $color-secondary;
	padding: 1.5rem;

	.container {
		border: 2px solid $color-primary;
		height: 100%;
		padding: 3.7rem 0 2rem 0;
		min-width: 100%;
		margin: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		background-image: url(../../../../uploads/2024/12/plant-green..svg),
			url(../../../../uploads/2024/12/plant-green-reverse..svg);
		/* Deux couches d'image */
		background-repeat: no-repeat, no-repeat;
		/* Empêche la répétition des deux couches */
		background-position: bottom left, top right;
		/* Première image en bas à gauche, deuxième en haut à droite */
		background-size: 15%, 15%;
		/* Taille des deux images */

		@media (max-width: 992px) {
			padding: 2rem;
			background-size: 30%, 30%;
		}

		.content {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 5.6rem;
			max-height: 50vh;

			.accordion-title {
				color: $color-primary-darker;
				transition: all 0.7s ease;
				text-transform: uppercase;
				font-weight: bold;

				&:hover {
					color: $color-primary;
					transition: all 0.7s ease;

					&::after {
						color: $color-primary;
						transition: all 0.7s ease;
					}
				}

				&::after {
					color: $color-primary-darker;
					transition: all 0.7s ease;
				}
			}

			.accordion-content {
				width: 100%;
				transition: all 0.7s ease-in;
			}

			.contact {
				a {
					color: $color-primary-darker;
					transition: all 0.7s ease;
					text-transform: uppercase;
					transition: padding-top 0.3s ease-in-out;
					text-underline-offset: 2px;

					&:hover {
						color: $color-primary;
						transition: all 0.3s ease;
						text-decoration: underline;
						text-underline-offset: 6px;
					}
				}
			}

			.img-header {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: flex-end;
				height: 100%;
				width: fit-content;
				max-width: fit-content;

				img {
					max-height: 100%;
					height: auto;
					width: 100%;
				}
			}

			.mobile {
				width: 20rem;
				display: flex;
				justify-content: center;

				@media (max-width: 992px) {
					display: none;
				}
			}
		}

		.mobile-content {
			display: none;

			@media (max-width: 992px) {
				display: flex;
				gap: 4.85rem;

				.accordion-title {
					color: $color-primary-darker;
					transition: all 0.7s ease;
					text-transform: uppercase;
					font-weight: bold;

					&:hover {
						color: $color-primary;
						transition: all 0.7s ease;

						&::after {
							color: $color-primary;
							transition: all 0.7s ease;
						}
					}

					&::after {
						color: $color-primary-darker;
						transition: all 0.7s ease;
					}
				}

				.contact {
					a {
						color: $color-primary-darker;
						transition: all 0.7s ease;
						text-transform: uppercase;

						&:hover {
							color: $color-primary;
							transition: all 0.7s ease;
						}
					}
				}
			}
		}
	}


	#logo {
		width: 100%;
		max-width: 100%;
		padding: 0 35%;

		@media (max-width: 992px) {
			padding: 0 7rem;
		}

		@media (max-width: 576px) {
			padding: 0 1rem;
		}
	}

	.btn-arrow {
		width: fit-content;
	}


}


#header {
	width: 100%;
	max-height: 4.75rem;
	background-color: $color-secondary;
	display: flex;
	align-items: center;
	left: 0;
	top: 0;
	z-index: 200;
	padding: 1.4rem 0;
	transition: all 0.4s linear;

	.container {
		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;
	}

	&.sticky {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1000;

		#logo {
			max-width: 10rem;
		}

		@media (max-width:1280px) {


			#logo {
				max-width: 8.95rem;
			}
		}

		@media (max-width:767px) {

			#logo {
				max-width: 6.95rem;
			}
		}
	}

	@media (max-width:1280px) {

		#menu {
			#extra-menu {
				height: 30%;
				width: 100%;
				justify-content: end;
			}
		}
	}

	#header-content {
		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;
		justify-content: space-between;
		gap: 1rem;
	}

	#menu-mobile-trigger {
		font-size: 31px;
		display: none;

		.trigger-bar {
			display: block;
			width: 25px;
			height: 2px;
			transition: all 0.2s linear;
			position: relative;
			background-color: $color-primary;

			&:not(:first-child) {
				margin-top: 6px;
			}
		}

		@media (max-width:1199px) {
			display: none;
		}
	}

	#logo {
		float: left;
	}

	#menu {
		transition: all 0.4s linear;
		float: right;
		display: flex;
		align-items: flex-end;
		height: 100%;
		flex-direction: column;
		justify-content: center;

		@media (max-width:1199px) {
			gap: 0.5rem;
		}

		#menu-main-container {
			display: flex;
			align-items: center;
			width: 100%;
			height: 100%;

			ul {
				height: 100%;
				display: flex;
				align-items: center;
				margin: 0;
				padding: 0;
				list-style: none;
				text-align: right;
				float: right;
				justify-content: space-between;

				.menu-item-object-page {
					transition: padding-top 0.3s ease-in-out;
					text-underline-offset: 2px;

					&:hover {
						color: $color-primary;
						transition: all 0.3s ease;
						text-decoration: underline;
						text-underline-offset: 6px;
					}
				}

				li {
					height: 100%;
					display: flex;
					align-items: center;
					float: left;
					position: relative;
					margin-right: 2.17rem;

					a {
						font-size: 1rem;
						display: block;
						font-weight: bold;
						color: $color-primary-darker;
						height: 100%;
						display: flex;
						align-items: center;
						text-transform: uppercase;
						transition: padding-top 0.3s ease-in-out;
						text-underline-offset: 2px;

						&:hover {
							color: $color-primary;
							transition: all 0.3s ease;
						}
					}

					&:last-child {
						margin-right: 0;
					}

					&.current-menu-item,
					&.current-menu-ancestor,
					&.current-menu-parent,
					&.current-product-ancestor,
					&.current-product_cat-ancestor,
					&.current-page-ancestor,
					&.menu-item-has-children:after {
						>a {
							color: $color-primary;
						}

						.sub-menu {
							li {
								a {
									color: $color-primary;
								}
							}
						}
					}

					.sub-menu {
						display: flex;
						flex-direction: column;
						top: 100%;
						opacity: 0;
						height: 0;
						padding: 0;
						position: absolute;
						text-align: left;
						margin-left: -12px;
						transform: scale(0.95) translateY(0);
						transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
						z-index: 10;
						overflow: hidden;
						border-radius: 0;
						gap: 0;
						border: 1px solid $color-primary-darker;

						li {
							white-space: nowrap;
							margin: 0;
							float: none;
							border-bottom: 1px solid $color-primary-darker;
							width: 100%;

							a {
								display: block;
								color: $color-primary;
								background-color: $color-link-hover;
								padding: 0.5rem 1rem;
								width: 100%;

								&:hover {
									color: $color-white;
									background-color: $color-primary-darker;
								}
							}

							&:last-child {
								margin-bottom: 0;
								border-bottom: 0px solid $color-gray-lighter;
							}

							&.current-menu-item,
							&.current-menu-ancestor,
							&.current-menu-parent,
							&.current-product-ancestor,
							&.current-product_cat-ancestor,
							&.current-page-ancestor {
								a {
									color: $color-primary-darker;

									&:hover {
										color: $color-white;
									}
								}
							}
						}

						li.anchor-link {
							a {
								color: $color-primary;
							}

							&.current-menu-item,
							&.current-menu-ancestor,
							&.current-menu-parent,
							&.current-product-ancestor,
							&.current-product_cat-ancestor,
							&.current-page-ancestor {
								a {
									color: $color-primary;
								}
							}

							a:hover {
								color: $color-white;
							}
						}

						li.menu-file {

							&:after {
								content: "\f08e";
								font-family: 'Font Awesome 6 Regular';
								position: absolute;
								right: 10px;
								opacity: 0;
								transition: all 0.4s ease;
							}
						}

						li.menu-file {
							&:hover:after {
								opacity: 1;
								transition: all 0.4s ease;
							}
						}
					}

					&.menu-item-has-children {
						margin-right: 3.17rem;

						&:after {
							font-family: "Font Awesome 6 Pro";
							content: '\f078';
							display: block;
							position: absolute;
							top: 50%;
							right: -1.5rem;
							font-weight: bold;
							transform: translateY(-50%);
							transform-origin: center;
							color: $color-primary-darker;
							transition: transform 0.25s;
							transition: all ease 0.7s;
						}

						&:hover {
							&:after {
								transform: translateY(-50%) rotate(180deg);
								color: $color-primary;
								transition: all ease 0.7s;
							}

							.sub-menu {
								height: auto;
								opacity: 1;
								transform: scale(1) translateY(0);
							}
						}
					}

					@media (max-width:1280px) {
						margin-right: 2rem;

						a {
							font-size: 1.1rem;
						}
					}
				}
			}
		}
	}

	#extra-menu {
		display: flex;
		flex-direction: row;
		height: 50%;
		align-items: flex-start;
		position: absolute;
		top: 5px;

		ul {
			display: flex;
			gap: 1.64rem;
			flex-direction: row;
			list-style: none;
			text-align: right;
			float: right;
			margin-top: 0px;
			margin-bottom: 0px;
			padding-inline-start: 0px;

			li {
				position: relative;
				color: $color-primary;
				transition: all ease 0.3s;
				border-bottom: solid transparent 1px;

				&:after {
					content: '';
					position: absolute;
					width: 100%;
					transform: scaleX(0);
					border-radius: 5px;
					height: 0.05em;
					bottom: 0;
					left: 0;
					transform-origin: bottom right;
					background: $color-primary;
					transition: transform .25s ease-out;
				}

				&:before {
					padding: 0 0.6rem 0 0;
					font-family: "Font Awesome 6 Pro";
					content: "\f054";
				}

				&:hover {
					color: $color-primary;
					transition: all ease 0.3s;

					&:after {
						transform: scaleX(1);
						transform-origin: bottom left;
						transition-duration: .3s;
					}

					a {
						color: $color-primary;
						transition: all ease 0.3s;
					}
				}
			}

			@media (max-width:1280px) {
				a {
					font-size: 1.1rem;
				}
			}
		}
	}
}

.mm-ocd--open {

	#menu-principal:before {
		border-top: 0;
	}

	.mm-ocd__content {
		.mm-spn.mm-spn--light {
			background: $color-gray-darker;

			&::after,
			&::before {
				opacity: 1;
				color: white;
			}

			li {
				&:last-of-type {
					&::after {
						border-top: 0px solid;
					}
				}

				a {
					color: white;
				}

				&.current_page_item a,
				&.current-menu-parent a {
					color: $color-primary;
				}
			}

			.menu-item-has-children {
				&::before {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					font-family: "Font Awesome 6 Pro";
					content: '\f054';
					height: 100%;
					width: 100%;
					top: 0;
					right: 30px;
					z-index: 10;
					position: absolute;
					transform: rotate(0) translate(0);
					border-top: none;
					border-right: none;
					opacity: 1;
					color: white;
				}

				a {
					&:after {
						border-right: 0px solid;
						opacity: 1;
					}
				}

				.sub-menu {
					z-index: 20;

					a {
						color: $color-primary;
					}

					.current-menu-item {
						a {
							color: $color-primary;
						}
					}
				}
			}
		}

		#menu-main-container {
			#menu-principal {
				height: auto;
				position: relative;
			}

			#extra-menu {
				height: auto;

				#menu-extra-menu {
					position: relative;
					left: 0;
					bottom: 0;
					top: auto;

					&:before {
						border-top: 0;
					}

					li {
						&:before {
							visibility: hidden;
						}

						a {
							color: $color-primary;
						}
					}
				}
			}
		}

		@media (max-width:767px) {
			#menu-principal {
				li {
					&:last-of-type {
						&::after {
							content: "";
							display: block;
							margin-left: var(--mm-spn-item-indent);
							border-top: 1px solid;
							opacity: .15;
						}
					}
				}
			}
		}
	}
}