//
// Template Styles
// --------------------------------------------------

#main {
    margin: 0 auto;

    &.marged {
        margin-top: var(--header-height);
    }
}

#page-homepage {
    .about {
        background-color: $color-primary;

        .grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            padding: 3rem 2rem;
            gap: 2rem;

            @media (max-width: 767px) {
                grid-template-columns: repeat(1, 1fr);
                grid-template-areas: "top" "bottom";
                padding: 0;
            }

            @media (max-width: 576px) {
                margin: 0;
                max-width: 100%;
            }

            .left {
                .content {
                    color: $color-white;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    height: 100%;

                    @media (max-width: 767px) {
                        gap: 1rem;
                    }

                    .diplomas {
                        display: flex;
                        text-align: center;
                        gap: 1rem;

                        .diploma-item {
                            flex: 1;

                            a {
                                color: $color-white;
                                transition: all ease .7s;

                                &:hover {
                                    color: $color-primary-darker;
                                    transition: all ease .7s;
                                }

                                p {
                                    text-transform: uppercase;
                                    font-weight: bold;
                                }

                                img {
                                    @media (max-width: 576px) {
                                        max-width: 30%;
                                    }
                                }
                            }
                        }
                    }

                    .content-text {
                        display: flex;
                        flex-direction: column;
                        gap: 0.8rem;

                        .pres-text {
                            .voir-plus {
                                background-color: transparent;
                                color: $color-white;
                                padding: 0;
                                margin: 2rem 0;
                                position: relative;
                                display: inline-block;

                                &::before {
                                    content: "";
                                    position: absolute;
                                    left: 0;
                                    bottom: 0;
                                    width: 0;
                                    height: 2px;
                                    background-color: $color-white;
                                    transition: width 0.4s ease-in-out;
                                }

                                &:hover::before {
                                    width: 100%;
                                }
                            }

                        }

                        .pres-text-hidden {
                            max-height: 0;
                            overflow: hidden;
                            opacity: 0;
                            transition: max-height 0.5s ease-in-out, opacity 0.3s ease-in-out;

                            &.active {
                                max-height: 1000px;
                                opacity: 1;
                            }
                        }
                    }
                }

                @media (max-width: 767px) {
                    grid-area: bottom;
                    padding: 3rem 2rem 5rem;
                }
            }

            .right {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;

                .right-mobile {
                    opacity: 0;
                }

                @media (max-width: 767px) {
                    align-items: center;
                    grid-area: top;
                    height: 40vh;

                    .right-mobile {
                        height: 100%;
                        width: 100%;
                        opacity: 1;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                        display: block;
                    }
                }

                img {
                    height: auto;
                    width: auto;

                    @media (max-width: 767px) {
                        display: none;
                    }
                }
            }
        }
    }

    .prestation {
        height: fit-content;
        background-color: $color-secondary;
        padding: 5rem 0;

        .container {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @media (max-width: 576px) {
                margin: 0;
                max-width: 100%;
            }

            .title {
                margin-bottom: 3rem;
                color: $color-primary-darker;
            }

            .list {
                .grid {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    margin: auto;
                    max-width: 50rem;
                    gap: 5rem;

                    @media (max-width: 576px) {
                        gap: 1rem;
                    }

                    a {
                        width: 100%;
                        align-items: center;

                        .card-img {
                            width: 70%;

                            @media (max-width: 756px) {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

#page-contact {
    margin-top: var(--header-height);

    .contact-header {
        height: 100svh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 5% 0 14% 0;
        display: flex;
        flex-direction: column;

        .container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            .contact-info {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                height: fit-content;
                gap: 1rem;

                @media (max-width: 767px) {
                    flex-direction: column;
                    height: 75%;
                    gap: 0;
                }

                .contact-item {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    text-transform: uppercase;
                    gap: 0.83rem;
                    color: $color-white;
                    transition: all ease 0.7s;
                    max-width: 16rem;

                    &:hover {
                        color: $color-primary;
                        transition: all ease 0.7s;

                        i {
                            color: $color-primary;
                            transition: all ease 0.7s;
                        }
                    }

                    i {
                        font-size: 2rem;
                    }
                }

                .line {
                    height: 100%;
                    width: 1px;

                    @media (max-width: 767px) {
                        height: 1px;
                        width: 50%;
                    }

                    img {
                        height: 100%;
                        width: 1rem;
                        object-fit: cover;

                        @media (max-width: 767px) {
                            display: none;
                        }
                    }

                    .mobile-line {
                        display: none;

                        @media (max-width: 767px) {
                            display: block;
                            height: 2px;
                            width: 100%;
                        }
                    }
                }
            }

            .contact-cta {
                display: flex;
                flex-direction: column;
                align-items: center;
                max-width: 34rem;
                margin: 0 auto;
                color: $color-white;
                text-align: center;

                .contact-text {
                    margin: 0.83rem 0 2.06rem 0;
                }
            }
        }
    }

    #post-content {
        background-color: $color-primary;
        padding-top: 0.1%;

        .container {
            margin-top: -7%;

            .container-form {
                background-color: $color-secondary;
                padding: 8% 12%;

                .gform_wrapper {
                    padding: 3.06rem 0 0;

                    .gfield--type-honeypot {
                        display: none;
                    }

                    .gform_heading {
                        display: none;

                    }

                    input[type="text"]::placeholder,
                    input[type="email"]::placeholder,
                    input[type="tel"]::placeholder,
                    textarea::placeholder {
                        color: $color-primary-darker;
                        text-transform: uppercase;
                        opacity: 0.4;
                        font-size: 1rem;
                    }

                    label {
                        display: none;
                    }

                    .ginput_container input,
                    .ginput_container textarea {
                        background-color: transparent !important;
                        border: 1px solid $color-primary-darker;
                        border-radius: 0;
                        min-height: 3rem;
                    }

                    .ginput_container input:focus,
                    .ginput_container textarea:focus {
                        background-color: transparent !important;
                        border: 1px solid $color-primary-darker !important;
                        border-radius: 0 !important;
                        min-height: 3rem !important;
                        outline: none !important;
                    }

                    .ginput_container input:valid,
                    .ginput_container textarea:valid {
                        color: $color-primary;
                        font-size: 1rem;
                    }

                    .ginput_container input:invalid,
                    .ginput_container textarea:invalid {
                        color: red;
                    }


                    .gform_footer {
                        justify-content: flex-start;
                    }
                }

                .title-form {
                    max-width: 36rem;
                    color: $color-primary-darker;

                    p {
                        margin-top: 0.83rem;
                        color: $color-primary-darker;
                    }
                }

                .gform_confirmation_wrapper {
                    .gform_confirmation_message {
                        margin-top: 2rem !important;
                        color: $color-primary !important;
                    }
                }

                .btn-primary {
                    border-radius: 3.2155rem 3.2155rem 0rem 3.2155rem;
                    width: fit-content;
                    background-color: $color-primary;
                    color: $color-white;
                    padding: 0.5rem 2.32rem;
                    transition: ease 0.7s;

                    &:hover {
                        background-color: $color-primary-darker;
                        transition: ease 0.7s;
                    }
                }
            }
        }
    }
}

#page-prestation-single {
    padding: 8.25 0 5.7rem;

    .benefits {
        height: fit-content;
        background-color: $color-secondary;
        padding: 9.17rem 0;

        .container-slider {
            max-width: 100%;

            h2 {
                color: $color-primary-darker;
                margin-bottom: 7rem;
            }

            .swiper {
                width: 100%;
                overflow: hidden;

                .swiper-wrapper {
                    display: flex;
                    transition: transform 0.3s ease-in-out;
                    align-items: stretch;

                    .swiper-slide {
                        flex-shrink: 0;
                        min-width: 200px;
                        max-width: 100%;
                        margin-right: 0px;
                        display: flex;
                        flex-direction: column;
                        align-items: stretch;
                    }
                }

                .swiper-button-prev,
                .swiper-button-next {
                    position: absolute;
                    top: 45%;
                    color: $color-primary-darker;
                    font-weight: 1000;
                    font-size: 1rem;
                    cursor: pointer;
                    z-index: 10;
                }

                .swiper-button-prev {
                    left: 1rem;
                }

                .swiper-button-next {
                    right: 1rem;
                }
            }
        }
    }

    .steps {
        height: fit-content;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 7.72rem 0;

        .container {
            h2 {
                text-align: center;
                color: $color-white;

                @media (max-width:576px) {
                    text-align: left;
                }
            }

            .grid {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 2rem;
                max-width: 92.94444rem;
                margin: 3.06rem 0 0 0;

                @media (max-width:576px) {
                    display: grid;
                    grid-template-columns: auto 1fr;
                    grid-template-rows: auto auto;
                    gap: 1rem;
                    width: 100%;
                }

                .steps-container {
                    display: flex;
                    flex-direction: column;
                    gap: 4rem;
                    grid-row: 1;
                    justify-content: space-between;

                    &.center-content {
                        justify-content: center;
                    }

                    @media (max-width:576px) {
                        padding: 0;
                        gap: 1rem;
                    }

                    .step-item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 10px;
                        color: $color-white;
                        text-align: center;

                        .step-icon {
                            width: 30%;
                            height: 30%;
                            object-fit: contain;
                        }

                        .step-number,
                        .step-text {
                            font-size: clamp(1rem, 2vw, 1.4rem);
                            line-height: clamp(1rem, 4vw, 1.7rem);
                            font-weight: bold;
                        }
                    }
                }

                .steps-right {
                    grid-column: 3;
                    max-width: 25.22222rem;
                    flex-direction: column-reverse;

                    @media (max-width:576px) {
                        grid-column: 2 / 3;
                        grid-row: 2 / 3;
                        max-width: 100%;
                        flex-direction: column;
                    }
                }

                .steps-center {
                    grid-column: 2;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    @media (max-width:576px) {
                        display: none;
                    }

                    .img-center {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 3rem 0;

                        img {
                            max-height: 25rem;
                            height: 100%;
                            width: 100%;
                        }
                    }
                }

                .steps-left {
                    grid-column: 1;
                    max-width: 25.22222rem;

                    @media (max-width:576px) {
                        grid-column: 2 / 3;
                        grid-row: 1 / 2;
                        max-width: 100%;
                    }
                }

                .mobile-img {
                    height: 100%;
                    width: 3rem;
                    background-image: url(../../../../uploads/2024/12/line-mobile..svg);
                    background-repeat: no-repeat;
                    background-position: center left;
                    background-size: contain;
                    display: none;
                    grid-column: 1 / 2;
                    grid-row: 1 / 3;

                    @media (max-width:576px) {
                        display: flex;
                    }
                }
            }
        }
    }

    .feedback {
        padding: 5.72rem 0;

        .feedback-background {
            background-repeat: no-repeat;
            background-position: bottom left;
            background-size: 15%;
            background-image: url(../../../../uploads/2024/12/plant-gold..svg);

            .container {

                .grid {
                    display: grid;
                    align-items: center;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 4rem;

                    @media (max-width:576px) {
                        grid-template-columns: repeat(1, 1fr);
                        gap: 4rem;
                    }

                    .left {
                        .feedback-container {
                            display: flex;
                            flex-direction: column;
                            gap: 8.5rem;

                            @media (max-width: 576px) {
                                gap: 4.5rem;
                            }

                            .feedback-item {
                                h2 {
                                    color: $color-primary-darker;
                                }

                                p {
                                    color: $color-primary-darker;
                                    margin: 1rem 0 2.22rem;
                                }

                                .feedback-text {
                                    p {
                                        color: $color-gray;
                                        margin: 0 0 2.22rem;

                                        &:last-child {
                                            margin: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .right {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;

                        img {
                            object-fit: contain;
                            max-width: 100%;
                            max-height: 34rem;
                            width: auto;
                        }

                        @media (max-width:576px) {
                            padding: 0;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}

#page-not-found {
    margin-top: var(--header-height);
    height: 100svh;
    background-color: $color-primary;

    #error-template {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .container {
            height: fit-content;
            background-color: $color-white;
            padding: 3rem 5rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: fit-content;
            gap: 2rem;

            .error-actions {
                display: flex;
                flex-wrap: wrap;
                gap: 1rem;
            }
        }
    }
}