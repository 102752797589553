@import "../../assets/styles/scss/variables";

.card-benefit {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
	gap: 1rem;
    justify-content: space-between;

	.card-img {
		background-color: $color-primary;
		padding: 4rem 3rem 2rem;
		border-radius: 20rem 20rem 0 16rem;
		height: fit-content;
		min-height: 12rem;

		img {
			width: 5rem;
			height: auto;
		}
	}

	.card-content {
		min-height: 4rem;

		h4 {
			display: flex;
			color: $color-primary;
			text-transform: uppercase;
			text-align: center;
		}
	}
}