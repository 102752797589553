@import "../../assets/styles/scss/variables";


.header-single.prestation-header {
  padding-top: calc(2.3rem + $header-height);
  height: fit-content;
  display: flex;
  background-color: $color-primary;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 15%;
  background-image: url(../../../../uploads/2024/12/plant..svg);

  @media(max-width: 768px) {
    padding-top: calc(1.33rem + $header-height);
  }

  .container {
    padding-bottom: 4rem;
    
    .grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @media(max-width: 576px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 2rem;
      }
    }
  
    .left {
      color: $color-white;
      display: flex;
      flex-direction: column;
      gap: .8rem;
      padding: 3.3rem 0 0 0;
    }
  
    .right {
      display: flex;
      justify-content: flex-end;
      padding-left: 40%;

      @media(max-width: 576px) {
        padding: 0 20%;
      }
  
      img {
        object-fit: cover;
        max-width: 100%;
        max-height: 34rem;
        width: auto;
      }
    }
  }
}