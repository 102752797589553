//
// Base Styles
// --------------------------------------------------

html,
body {
  font-family: 'Lexend', sans-serif;
  font-size: $regular; //18px
  line-height: $text-base-line-height;
  font-weight: normal;
  color: $color-text;
  scroll-behavior: smooth;

  @media (max-width:767px) {
    font-size: $small;
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  margin: 0;
}

.unscroll {
  overflow-y: hidden !important;
}

.is-hidden,
.hidden {
  display: none !important;
}

.invisible,
.not-visible {
  visibility: hidden;
}

.xsmall {
  font-size: $xsmall;
  line-height: normal;
  font-weight: normal;
}

.small {
  font-size: $small;
  line-height: normal;
  font-weight: normal;
}

p,
li,
.regular {
  font-size: clamp(1rem, 2vw, 1rem);
  line-height: clamp(1.6rem, 4vw, 1.4rem);
  font-weight: normal;
  font-family: 'Lexend', sans-serif;
}

.sub-title {
  font-size: clamp(1.1rem, 2vw, 1.4rem);
  line-height: clamp(1.6rem, 4vw, 1.7rem);
  font-weight: bold;
}

a {
  font-weight: bold;
  font-family: 'Lexend', sans-serif;

  &:last-child {
    margin-bottom: 0;
  }

  strong {
    font-weight: bold;
  }
}

.headline {
  font-size: clamp(1rem, 2vw, 1.3rem);
  line-height: clamp(1.6rem, 3vw, 1.55rem);
  font-weight: 500;
}

h6,
.medium {
  font-size: $medium;
  line-height: normal;
  font-weight: 500;
}

h5,
.big {
  font-size: $big;
  line-height: normal;
  font-weight: 500;
}

h4,
.large:not(input):not(textarea) {
  font-size: clamp(0.6rem, 4vw, 1.7rem);
  line-height: clamp(1.6rem, 3.8vw, 2.15rem);
  font-weight: 500;
  font-family: 'Lexend', sans-serif;
}

h3,
.massive {
  font-size: clamp(2rem, 7vw, 4.44444rem);
  line-height: clamp(1.6rem, 9vw, 4.77778rem);
  font-weight: 400;
  font-family: 'DM Serif Display', serif;
}

h2,
h2 p,
.huge {
  font-size: clamp(2rem, 7vw, 3.55556rem);
  line-height: clamp(1.6rem, 9vw, 3.88889rem);
  font-weight: 400;
  font-family: 'DM Serif Display', serif;
}

h1,
.gigantic {
  font-size: clamp(2rem, 7vw, 3.55556rem);
  line-height: clamp(1.6rem, 9vw, 3.88889rem);
  font-weight: 400;
  font-family: 'DM Serif Display', serif;
}

.fixed {
  position: fixed;
}

.accordion {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: $color-primary;
    opacity: .3;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  .accordion-title {
    padding: $large $xsmall;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $small;
    cursor: pointer;
    color: $color-primary-darker;
    padding: 0;
    margin: 0;
    transition: transform 0.7s ease-in-out, opacity 0.7s ease-in-out;

    &:after {
      content: "\f078";
      font-family: 'Font Awesome 6 Pro';
      display: block;
      left: 0;
      opacity: 1;
      font-weight: bold;
      font-size: 1.3rem;
      transition: transform 0.7s ease;
      color: $color-primary-darker;
    }

    &.is-open {
      &:after {
        transform: rotate(180deg);
        transition: transform 0.7s ease;
        color: $color-primary;
      }
    }
  }

  .accordion-content {
    display: block;
    padding: 0;
    padding-bottom: 0;
    margin: 0;
    list-style: none;
    border: 1px solid $color-primary-darker;
    position: absolute;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transform-origin: top;
    transform: scale(0.95);
    transition: max-height 0.25s ease-in-out, opacity 0.25s ease-in-out, transform 0.25s ease-in-out;

    .item {
      border-top: 1px solid $color-primary-darker;
      background-color: $color-secondary;
      transition: ease 0.5s;
      padding: 0.5rem 1rem;

      &:first-child {
        border-top: none;
      }

      &:hover {
        background-color: $color-primary-darker;
        transition: ease 0.5s;

        a {
          color: $color-white;
          transition: ease 0.5s;
        }
      }
    }
  }

  &.is-open .accordion-content {
    max-height: 500px;
    transition: max-height 0.25s ease-in-out, opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
    opacity: 1;
    transform: scale(1) translateY(0);
  }

  .accordion-title:hover+.accordion-content,
  .accordion-content:hover {
    max-height: fit-content;
    opacity: 1;
    transform: scale(1);
    transition: max-height 0.25s ease-in-out, opacity 0.25s ease-in-out, transform 0.25s ease-in-out;

  }
}




.cta {
  height: 100svh;

  @media (max-width: 767px) {
    height: 50svh;
  }
}

.btn-primary {
  border-radius: 3.2155rem 3.2155rem 0rem 3.2155rem;
  width: fit-content;
  background-color: $color-white;
  padding: 0.83rem 2.32rem;
  transition: ease 0.7s;

  &:hover {
    background-color: $color-primary;
    transition: ease 0.7s;
  }
}

.btn-secondary {
  border-radius: 3.2155rem 3.2155rem 0rem 3.2155rem;
  width: fit-content;
  background-color: $color-primary-darker;
  padding: 0.83rem 2.32rem;
  transition: ease 0.7s;
  color: $color-white;
  border: 1px solid $color-primary-darker;

  &:hover {
    background-color: $color-white;
    color: $color-primary-darker;
    transition: ease 0.7s;
    border: 1px solid $color-primary-darker;

    i {
      color: $color-primary-darker;
      transition: ease 0.7s;
    }
  }
}

.btn-arrow {
  padding: 0.8rem 1rem;
  height: fit-content;
  width: fit-content;
  border-radius: 20rem 20rem 0rem 20rem;
  background-color: $color-primary-darker;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease 0.5s;

  &:hover {
    background-color: $color-primary;
    cursor: pointer;
    transition: ease 0.5s;

    i {
      transform: rotate(0);
      transition: ease 0.5s;
    }
  }

  &.anchor {
    &:hover {
      i {
        transform: rotate(135deg);
        transition: ease 0.5s;
      }
    }

  }

  i {
    transform: rotate(90deg);
    color: $color-secondary;
    transition: ease 0.5s;
    font-size: larger;
  }
}