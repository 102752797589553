@import "../../assets/styles/scss/variables";

.card-service {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 1rem;
	overflow: hidden;

	.card-img {
		overflow: hidden;

		img {
			object-fit: cover;
			max-width: 100%;
			height: 100%;
			max-height: 100%;
			width: 100%;
			scale: 1.02;
			transition: all 0.7s ease;
		}
	}

	.card-content {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 1rem;

		.title-card {
			font-family: 'Lexend';
			font-size: clamp(0.2rem, 3vw, 1.7rem);
			font-style: normal;
			font-weight: 600;
			line-height: 2.15rem;
			text-transform: uppercase;
			color: $color-primary-darker;
			margin: 0;
		}

		.btn-arrow {
			@media (max-width: 576px) {
				padding: .4rem .6rem;

				i {
					font-size: 14px;
				}
			}
		}
	}

	&:hover {
		.card-img {
			img {
				scale: 1;
				transition: all 0.7s ease;
			}
		}

		.btn-arrow {
			background-color: $color-primary;
			cursor: pointer;
			transition: ease 0.5s;

			i {
				transform: rotate(0);
				transition: ease 0.5s;
			}
		}
	}
}