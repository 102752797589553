@import "../../assets/styles/scss/variables";

.example{
 	color: $color-primary;
}

@media (max-width: 1199px) {
	.example{
		color: red;
	}
}